/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CategoryAttachment,
  CategoryAttachmentWithId,
  PatchedCategoryAttachment,
} from '../models';
import {
    CategoryAttachmentFromJSON,
    CategoryAttachmentToJSON,
    CategoryAttachmentWithIdFromJSON,
    CategoryAttachmentWithIdToJSON,
    PatchedCategoryAttachmentFromJSON,
    PatchedCategoryAttachmentToJSON,
} from '../models';

export interface ApiGrexmanagerCategoryAttachmentCreateRequest {
    categoryAttachment: CategoryAttachment;
}

export interface ApiGrexmanagerCategoryAttachmentDestroyRequest {
    id: number;
    force?: boolean;
}

export interface ApiGrexmanagerCategoryAttachmentPartialUpdateRequest {
    id: number;
    patchedCategoryAttachment?: PatchedCategoryAttachment;
}

export interface ApiGrexmanagerCategoryAttachmentRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerCategoryAttachmentUpdateRequest {
    id: number;
    categoryAttachment: CategoryAttachment;
}

/**
 * 
 */
export class CategoryAttachmentApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentCreateRaw(requestParameters: ApiGrexmanagerCategoryAttachmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryAttachmentWithId>> {
        if (requestParameters.categoryAttachment === null || requestParameters.categoryAttachment === undefined) {
            throw new runtime.RequiredError('categoryAttachment','Required parameter requestParameters.categoryAttachment was null or undefined when calling apiGrexmanagerCategoryAttachmentCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/category-attachment/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryAttachmentToJSON(requestParameters.categoryAttachment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryAttachmentWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentCreate(requestParameters: ApiGrexmanagerCategoryAttachmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryAttachmentWithId> {
        const response = await this.apiGrexmanagerCategoryAttachmentCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentDestroyRaw(requestParameters: ApiGrexmanagerCategoryAttachmentDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerCategoryAttachmentDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/category-attachment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentDestroy(requestParameters: ApiGrexmanagerCategoryAttachmentDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerCategoryAttachmentDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of category_attachment
     */
    async apiGrexmanagerCategoryAttachmentListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryAttachmentWithId>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/category-attachment/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryAttachmentWithIdFromJSON));
    }

    /**
     * Endpoint for receiving a list of category_attachment
     */
    async apiGrexmanagerCategoryAttachmentList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryAttachmentWithId>> {
        const response = await this.apiGrexmanagerCategoryAttachmentListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentPartialUpdateRaw(requestParameters: ApiGrexmanagerCategoryAttachmentPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryAttachmentWithId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerCategoryAttachmentPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/category-attachment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedCategoryAttachmentToJSON(requestParameters.patchedCategoryAttachment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryAttachmentWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentPartialUpdate(requestParameters: ApiGrexmanagerCategoryAttachmentPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryAttachmentWithId> {
        const response = await this.apiGrexmanagerCategoryAttachmentPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiveing information about a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentRetrieveRaw(requestParameters: ApiGrexmanagerCategoryAttachmentRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryAttachmentWithId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerCategoryAttachmentRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/category-attachment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryAttachmentWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiveing information about a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentRetrieve(requestParameters: ApiGrexmanagerCategoryAttachmentRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryAttachmentWithId> {
        const response = await this.apiGrexmanagerCategoryAttachmentRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentUpdateRaw(requestParameters: ApiGrexmanagerCategoryAttachmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryAttachmentWithId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerCategoryAttachmentUpdate.');
        }

        if (requestParameters.categoryAttachment === null || requestParameters.categoryAttachment === undefined) {
            throw new runtime.RequiredError('categoryAttachment','Required parameter requestParameters.categoryAttachment was null or undefined when calling apiGrexmanagerCategoryAttachmentUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/category-attachment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryAttachmentToJSON(requestParameters.categoryAttachment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryAttachmentWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a category_attachment
     */
    async apiGrexmanagerCategoryAttachmentUpdate(requestParameters: ApiGrexmanagerCategoryAttachmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryAttachmentWithId> {
        const response = await this.apiGrexmanagerCategoryAttachmentUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
