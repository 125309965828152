// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#LoginScreen-Container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-ultra-light);

  -webkit-user-select: none;

          user-select: none;
  display: flex;
}

#LoginScreen-Section {
  min-width: 400px;
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-right: 2px solid var(--line-color);
}

#LoginScreen-LoginHolder {
  width: 320px;
  margin-bottom: 120px;
}

#LoginScreen-LoginHolder .label {
  margin-top: 10px;
}

#LoginScreen-LoginHolder .Button {
  width: 100%;
  margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/auth/LoginScreen.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,WAAW;EACX,kDAAkD;;EAElD,yBAAiB;;UAAjB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["#LoginScreen-Container {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  height: 100%;\n  width: 100%;\n  background-color: var(--primary-color-ultra-light);\n\n  user-select: none;\n  display: flex;\n}\n\n#LoginScreen-Section {\n  min-width: 400px;\n  width: 50%;\n  height: 100%;\n  background-color: white;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  border-right: 2px solid var(--line-color);\n}\n\n#LoginScreen-LoginHolder {\n  width: 320px;\n  margin-bottom: 120px;\n}\n\n#LoginScreen-LoginHolder .label {\n  margin-top: 10px;\n}\n\n#LoginScreen-LoginHolder .Button {\n  width: 100%;\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
