/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GrexUser,
  GrexUserBlocker,
  GrexUserCreate,
  GrexUserList,
  GrexUserLoad,
  GrexUserProjectRoleListResponse,
  GrexUserResetMfa,
  GrexUserResetPassword,
  GrexUserSubregionListResponse,
  GrexUserTemplateReportListResponse,
  GrexUserUpdate,
} from '../models';
import {
    GrexUserFromJSON,
    GrexUserToJSON,
    GrexUserBlockerFromJSON,
    GrexUserBlockerToJSON,
    GrexUserCreateFromJSON,
    GrexUserCreateToJSON,
    GrexUserListFromJSON,
    GrexUserListToJSON,
    GrexUserLoadFromJSON,
    GrexUserLoadToJSON,
    GrexUserProjectRoleListResponseFromJSON,
    GrexUserProjectRoleListResponseToJSON,
    GrexUserResetMfaFromJSON,
    GrexUserResetMfaToJSON,
    GrexUserResetPasswordFromJSON,
    GrexUserResetPasswordToJSON,
    GrexUserSubregionListResponseFromJSON,
    GrexUserSubregionListResponseToJSON,
    GrexUserTemplateReportListResponseFromJSON,
    GrexUserTemplateReportListResponseToJSON,
    GrexUserUpdateFromJSON,
    GrexUserUpdateToJSON,
} from '../models';

export interface ApiGrexmanagerUserBlkCreateRequest {
    id: number;
    grexUserBlocker?: GrexUserBlocker;
}

export interface ApiGrexmanagerUserDestroyRequest {
    id: number;
}

export interface ApiGrexmanagerUserNewCreateRequest {
    grexUserCreate: GrexUserCreate;
}

export interface ApiGrexmanagerUserProjectRoleCreateRequest {
    id: number;
    grexUserProjectRoleListResponse: Array<GrexUserProjectRoleListResponse>;
}

export interface ApiGrexmanagerUserProjectRoleListRequest {
    id: number;
}

export interface ApiGrexmanagerUserProjectRoleUpdateRequest {
    id: number;
    grexUserProjectRoleListResponse: Array<GrexUserProjectRoleListResponse>;
}

export interface ApiGrexmanagerUserResetMfaCreateRequest {
    id: number;
    grexUserBlocker?: GrexUserBlocker;
}

export interface ApiGrexmanagerUserResetPasswordCreateRequest {
    id: number;
    grexUserResetPassword: GrexUserResetPassword;
}

export interface ApiGrexmanagerUserRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerUserSubregionCreateRequest {
    id: number;
    grexUserSubregionListResponse: Array<GrexUserSubregionListResponse>;
}

export interface ApiGrexmanagerUserSubregionListRequest {
    id: number;
}

export interface ApiGrexmanagerUserSubregionUpdateRequest {
    id: number;
    grexUserSubregionListResponse: Array<GrexUserSubregionListResponse>;
}

export interface ApiGrexmanagerUserTemplateReportCreateRequest {
    id: number;
    grexUserTemplateReportListResponse: Array<GrexUserTemplateReportListResponse>;
}

export interface ApiGrexmanagerUserTemplateReportListRequest {
    id: number;
}

export interface ApiGrexmanagerUserTemplateReportUpdateRequest {
    id: number;
    grexUserTemplateReportListResponse: Array<GrexUserTemplateReportListResponse>;
}

export interface ApiGrexmanagerUserUpdateRequest {
    id: number;
    grexUserUpdate?: GrexUserUpdate;
}

/**
 * 
 */
export class GrexUserAdminApi extends runtime.BaseAPI {

    /**
     * block user
     */
    async apiGrexmanagerUserBlkCreateRaw(requestParameters: ApiGrexmanagerUserBlkCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUserBlocker>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserBlkCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/blk/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserBlockerToJSON(requestParameters.grexUserBlocker),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserBlockerFromJSON(jsonValue));
    }

    /**
     * block user
     */
    async apiGrexmanagerUserBlkCreate(requestParameters: ApiGrexmanagerUserBlkCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUserBlocker> {
        const response = await this.apiGrexmanagerUserBlkCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete user
     */
    async apiGrexmanagerUserDestroyRaw(requestParameters: ApiGrexmanagerUserDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * delete user
     */
    async apiGrexmanagerUserDestroy(requestParameters: ApiGrexmanagerUserDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * force mfa for a all client users
     */
    async apiGrexmanagerUserForceMfaCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/force-mfa/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * force mfa for a all client users
     */
    async apiGrexmanagerUserForceMfaCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserForceMfaCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGrexmanagerUserListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserListFromJSON));
    }

    /**
     */
    async apiGrexmanagerUserList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserList>> {
        const response = await this.apiGrexmanagerUserListRaw(initOverrides);
        return await response.value();
    }

    /**
     * create user
     */
    async apiGrexmanagerUserNewCreateRaw(requestParameters: ApiGrexmanagerUserNewCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUser>> {
        if (requestParameters.grexUserCreate === null || requestParameters.grexUserCreate === undefined) {
            throw new runtime.RequiredError('grexUserCreate','Required parameter requestParameters.grexUserCreate was null or undefined when calling apiGrexmanagerUserNewCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserCreateToJSON(requestParameters.grexUserCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserFromJSON(jsonValue));
    }

    /**
     * create user
     */
    async apiGrexmanagerUserNewCreate(requestParameters: ApiGrexmanagerUserNewCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUser> {
        const response = await this.apiGrexmanagerUserNewCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create project role
     */
    async apiGrexmanagerUserProjectRoleCreateRaw(requestParameters: ApiGrexmanagerUserProjectRoleCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserProjectRoleListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserProjectRoleCreate.');
        }

        if (requestParameters.grexUserProjectRoleListResponse === null || requestParameters.grexUserProjectRoleListResponse === undefined) {
            throw new runtime.RequiredError('grexUserProjectRoleListResponse','Required parameter requestParameters.grexUserProjectRoleListResponse was null or undefined when calling apiGrexmanagerUserProjectRoleCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/project-role/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.grexUserProjectRoleListResponse.map(GrexUserProjectRoleListResponseToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserProjectRoleListResponseFromJSON));
    }

    /**
     * create project role
     */
    async apiGrexmanagerUserProjectRoleCreate(requestParameters: ApiGrexmanagerUserProjectRoleCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserProjectRoleListResponse>> {
        const response = await this.apiGrexmanagerUserProjectRoleCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get project role
     */
    async apiGrexmanagerUserProjectRoleListRaw(requestParameters: ApiGrexmanagerUserProjectRoleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserProjectRoleListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserProjectRoleList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/project-role/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserProjectRoleListResponseFromJSON));
    }

    /**
     * get project role
     */
    async apiGrexmanagerUserProjectRoleList(requestParameters: ApiGrexmanagerUserProjectRoleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserProjectRoleListResponse>> {
        const response = await this.apiGrexmanagerUserProjectRoleListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update project role
     */
    async apiGrexmanagerUserProjectRoleUpdateRaw(requestParameters: ApiGrexmanagerUserProjectRoleUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserProjectRoleListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserProjectRoleUpdate.');
        }

        if (requestParameters.grexUserProjectRoleListResponse === null || requestParameters.grexUserProjectRoleListResponse === undefined) {
            throw new runtime.RequiredError('grexUserProjectRoleListResponse','Required parameter requestParameters.grexUserProjectRoleListResponse was null or undefined when calling apiGrexmanagerUserProjectRoleUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/project-role/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.grexUserProjectRoleListResponse.map(GrexUserProjectRoleListResponseToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserProjectRoleListResponseFromJSON));
    }

    /**
     * update project role
     */
    async apiGrexmanagerUserProjectRoleUpdate(requestParameters: ApiGrexmanagerUserProjectRoleUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserProjectRoleListResponse>> {
        const response = await this.apiGrexmanagerUserProjectRoleUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset mfa user
     */
    async apiGrexmanagerUserResetMfaCreateRaw(requestParameters: ApiGrexmanagerUserResetMfaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUserResetMfa>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserResetMfaCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/reset-mfa/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserBlockerToJSON(requestParameters.grexUserBlocker),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserResetMfaFromJSON(jsonValue));
    }

    /**
     * Reset mfa user
     */
    async apiGrexmanagerUserResetMfaCreate(requestParameters: ApiGrexmanagerUserResetMfaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUserResetMfa> {
        const response = await this.apiGrexmanagerUserResetMfaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset Password
     */
    async apiGrexmanagerUserResetPasswordCreateRaw(requestParameters: ApiGrexmanagerUserResetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserResetPasswordCreate.');
        }

        if (requestParameters.grexUserResetPassword === null || requestParameters.grexUserResetPassword === undefined) {
            throw new runtime.RequiredError('grexUserResetPassword','Required parameter requestParameters.grexUserResetPassword was null or undefined when calling apiGrexmanagerUserResetPasswordCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/reset-password/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserResetPasswordToJSON(requestParameters.grexUserResetPassword),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reset Password
     */
    async apiGrexmanagerUserResetPasswordCreate(requestParameters: ApiGrexmanagerUserResetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserResetPasswordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * load user
     */
    async apiGrexmanagerUserRetrieveRaw(requestParameters: ApiGrexmanagerUserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUserLoad>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserLoadFromJSON(jsonValue));
    }

    /**
     * load user
     */
    async apiGrexmanagerUserRetrieve(requestParameters: ApiGrexmanagerUserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUserLoad> {
        const response = await this.apiGrexmanagerUserRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create subregions access
     */
    async apiGrexmanagerUserSubregionCreateRaw(requestParameters: ApiGrexmanagerUserSubregionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserSubregionListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserSubregionCreate.');
        }

        if (requestParameters.grexUserSubregionListResponse === null || requestParameters.grexUserSubregionListResponse === undefined) {
            throw new runtime.RequiredError('grexUserSubregionListResponse','Required parameter requestParameters.grexUserSubregionListResponse was null or undefined when calling apiGrexmanagerUserSubregionCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/subregion/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.grexUserSubregionListResponse.map(GrexUserSubregionListResponseToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserSubregionListResponseFromJSON));
    }

    /**
     * create subregions access
     */
    async apiGrexmanagerUserSubregionCreate(requestParameters: ApiGrexmanagerUserSubregionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserSubregionListResponse>> {
        const response = await this.apiGrexmanagerUserSubregionCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get subregions access
     */
    async apiGrexmanagerUserSubregionListRaw(requestParameters: ApiGrexmanagerUserSubregionListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserSubregionListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserSubregionList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/subregion/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserSubregionListResponseFromJSON));
    }

    /**
     * get subregions access
     */
    async apiGrexmanagerUserSubregionList(requestParameters: ApiGrexmanagerUserSubregionListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserSubregionListResponse>> {
        const response = await this.apiGrexmanagerUserSubregionListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update subregions access
     */
    async apiGrexmanagerUserSubregionUpdateRaw(requestParameters: ApiGrexmanagerUserSubregionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserSubregionListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserSubregionUpdate.');
        }

        if (requestParameters.grexUserSubregionListResponse === null || requestParameters.grexUserSubregionListResponse === undefined) {
            throw new runtime.RequiredError('grexUserSubregionListResponse','Required parameter requestParameters.grexUserSubregionListResponse was null or undefined when calling apiGrexmanagerUserSubregionUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/subregion/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.grexUserSubregionListResponse.map(GrexUserSubregionListResponseToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserSubregionListResponseFromJSON));
    }

    /**
     * update subregions access
     */
    async apiGrexmanagerUserSubregionUpdate(requestParameters: ApiGrexmanagerUserSubregionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserSubregionListResponse>> {
        const response = await this.apiGrexmanagerUserSubregionUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create TemplateReport access
     */
    async apiGrexmanagerUserTemplateReportCreateRaw(requestParameters: ApiGrexmanagerUserTemplateReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserTemplateReportListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserTemplateReportCreate.');
        }

        if (requestParameters.grexUserTemplateReportListResponse === null || requestParameters.grexUserTemplateReportListResponse === undefined) {
            throw new runtime.RequiredError('grexUserTemplateReportListResponse','Required parameter requestParameters.grexUserTemplateReportListResponse was null or undefined when calling apiGrexmanagerUserTemplateReportCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/template-report/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.grexUserTemplateReportListResponse.map(GrexUserTemplateReportListResponseToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserTemplateReportListResponseFromJSON));
    }

    /**
     * create TemplateReport access
     */
    async apiGrexmanagerUserTemplateReportCreate(requestParameters: ApiGrexmanagerUserTemplateReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserTemplateReportListResponse>> {
        const response = await this.apiGrexmanagerUserTemplateReportCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get TemplateReport access
     */
    async apiGrexmanagerUserTemplateReportListRaw(requestParameters: ApiGrexmanagerUserTemplateReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserTemplateReportListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserTemplateReportList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/template-report/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserTemplateReportListResponseFromJSON));
    }

    /**
     * get TemplateReport access
     */
    async apiGrexmanagerUserTemplateReportList(requestParameters: ApiGrexmanagerUserTemplateReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserTemplateReportListResponse>> {
        const response = await this.apiGrexmanagerUserTemplateReportListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update TemplateReport access
     */
    async apiGrexmanagerUserTemplateReportUpdateRaw(requestParameters: ApiGrexmanagerUserTemplateReportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUserTemplateReportListResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserTemplateReportUpdate.');
        }

        if (requestParameters.grexUserTemplateReportListResponse === null || requestParameters.grexUserTemplateReportListResponse === undefined) {
            throw new runtime.RequiredError('grexUserTemplateReportListResponse','Required parameter requestParameters.grexUserTemplateReportListResponse was null or undefined when calling apiGrexmanagerUserTemplateReportUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/template-report/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.grexUserTemplateReportListResponse.map(GrexUserTemplateReportListResponseToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserTemplateReportListResponseFromJSON));
    }

    /**
     * update TemplateReport access
     */
    async apiGrexmanagerUserTemplateReportUpdate(requestParameters: ApiGrexmanagerUserTemplateReportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUserTemplateReportListResponse>> {
        const response = await this.apiGrexmanagerUserTemplateReportUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update user
     */
    async apiGrexmanagerUserUpdateRaw(requestParameters: ApiGrexmanagerUserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerUserUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserUpdateToJSON(requestParameters.grexUserUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserFromJSON(jsonValue));
    }

    /**
     * update user
     */
    async apiGrexmanagerUserUpdate(requestParameters: ApiGrexmanagerUserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUser> {
        const response = await this.apiGrexmanagerUserUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
