/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrexManagerStatusResponse
 */
export interface GrexManagerStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GrexManagerStatusResponse
     */
    databaseHealthy: boolean;
}

/**
 * Check if a given object implements the GrexManagerStatusResponse interface.
 */
export function instanceOfGrexManagerStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "databaseHealthy" in value;

    return isInstance;
}

export function GrexManagerStatusResponseFromJSON(json: any): GrexManagerStatusResponse {
    return GrexManagerStatusResponseFromJSONTyped(json, false);
}

export function GrexManagerStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexManagerStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'databaseHealthy': json['database_healthy'],
    };
}

export function GrexManagerStatusResponseToJSON(value?: GrexManagerStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'database_healthy': value.databaseHealthy,
    };
}

