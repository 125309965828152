// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#LoginScreen-Background {
  width: 50%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./../shared/components/LoginBackground.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,yDAAiD;EACjD,4BAA4B;EAC5B,sBAAsB;EACtB,kCAAkC;EAClC,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB","sourcesContent":["#LoginScreen-Background {\n  width: 50%;\n  height: 100%;\n  background-image: url(\"../images/background.jpg\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center center;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
