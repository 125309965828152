/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrexUserBlocker
 */
export interface GrexUserBlocker {
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserBlocker
     */
    blocked?: boolean;
}

/**
 * Check if a given object implements the GrexUserBlocker interface.
 */
export function instanceOfGrexUserBlocker(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GrexUserBlockerFromJSON(json: any): GrexUserBlocker {
    return GrexUserBlockerFromJSONTyped(json, false);
}

export function GrexUserBlockerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserBlocker {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
    };
}

export function GrexUserBlockerToJSON(value?: GrexUserBlocker | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocked': value.blocked,
    };
}

