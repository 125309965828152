/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientCreateTemplateReport,
  ClientTemplateReport,
  ClientTemplateReportInstance,
  ClientUpdateTemplateReportRequest,
  ClientUploadTemplateReportRequest,
} from '../models';
import {
    ClientCreateTemplateReportFromJSON,
    ClientCreateTemplateReportToJSON,
    ClientTemplateReportFromJSON,
    ClientTemplateReportToJSON,
    ClientTemplateReportInstanceFromJSON,
    ClientTemplateReportInstanceToJSON,
    ClientUpdateTemplateReportRequestFromJSON,
    ClientUpdateTemplateReportRequestToJSON,
    ClientUploadTemplateReportRequestFromJSON,
    ClientUploadTemplateReportRequestToJSON,
} from '../models';

export interface ApiGrexsuperadminClientTemplateReportCreateRequest {
    clientId: number;
    clientCreateTemplateReport: ClientCreateTemplateReport;
}

export interface ApiGrexsuperadminClientTemplateReportCreate2Request {
    clientId: number;
    templateReportId: number;
    clientUploadTemplateReportRequest: ClientUploadTemplateReportRequest;
}

export interface ApiGrexsuperadminClientTemplateReportDestroyRequest {
    clientId: number;
    templateReportId: number;
}

export interface ApiGrexsuperadminClientTemplateReportListRequest {
    clientId: number;
}

export interface ApiGrexsuperadminClientTemplateReportRetrieveRequest {
    clientId: number;
    templateReportId: number;
}

export interface ApiGrexsuperadminClientTemplateReportUpdateRequest {
    clientId: number;
    templateReportId: number;
    clientUpdateTemplateReportRequest: ClientUpdateTemplateReportRequest;
}

/**
 * 
 */
export class GrexSuperAdminTemplateReportApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a template report
     */
    async apiGrexsuperadminClientTemplateReportCreateRaw(requestParameters: ApiGrexsuperadminClientTemplateReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTemplateReport>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateReportCreate.');
        }

        if (requestParameters.clientCreateTemplateReport === null || requestParameters.clientCreateTemplateReport === undefined) {
            throw new runtime.RequiredError('clientCreateTemplateReport','Required parameter requestParameters.clientCreateTemplateReport was null or undefined when calling apiGrexsuperadminClientTemplateReportCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-report/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientCreateTemplateReportToJSON(requestParameters.clientCreateTemplateReport),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTemplateReportFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a template report
     */
    async apiGrexsuperadminClientTemplateReportCreate(requestParameters: ApiGrexsuperadminClientTemplateReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTemplateReport> {
        const response = await this.apiGrexsuperadminClientTemplateReportCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for uploading template report history
     */
    async apiGrexsuperadminClientTemplateReportCreate2Raw(requestParameters: ApiGrexsuperadminClientTemplateReportCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateReportCreate2.');
        }

        if (requestParameters.templateReportId === null || requestParameters.templateReportId === undefined) {
            throw new runtime.RequiredError('templateReportId','Required parameter requestParameters.templateReportId was null or undefined when calling apiGrexsuperadminClientTemplateReportCreate2.');
        }

        if (requestParameters.clientUploadTemplateReportRequest === null || requestParameters.clientUploadTemplateReportRequest === undefined) {
            throw new runtime.RequiredError('clientUploadTemplateReportRequest','Required parameter requestParameters.clientUploadTemplateReportRequest was null or undefined when calling apiGrexsuperadminClientTemplateReportCreate2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-report/{template_report_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_report_id"}}`, encodeURIComponent(String(requestParameters.templateReportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientUploadTemplateReportRequestToJSON(requestParameters.clientUploadTemplateReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for uploading template report history
     */
    async apiGrexsuperadminClientTemplateReportCreate2(requestParameters: ApiGrexsuperadminClientTemplateReportCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexsuperadminClientTemplateReportCreate2Raw(requestParameters, initOverrides);
    }

    /**
     * delete TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportDestroyRaw(requestParameters: ApiGrexsuperadminClientTemplateReportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateReportDestroy.');
        }

        if (requestParameters.templateReportId === null || requestParameters.templateReportId === undefined) {
            throw new runtime.RequiredError('templateReportId','Required parameter requestParameters.templateReportId was null or undefined when calling apiGrexsuperadminClientTemplateReportDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-report/{template_report_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_report_id"}}`, encodeURIComponent(String(requestParameters.templateReportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportDestroy(requestParameters: ApiGrexsuperadminClientTemplateReportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexsuperadminClientTemplateReportDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * get TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportListRaw(requestParameters: ApiGrexsuperadminClientTemplateReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientTemplateReport>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateReportList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-report/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientTemplateReportFromJSON));
    }

    /**
     * get TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportList(requestParameters: ApiGrexsuperadminClientTemplateReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientTemplateReport>> {
        const response = await this.apiGrexsuperadminClientTemplateReportListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportRetrieveRaw(requestParameters: ApiGrexsuperadminClientTemplateReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTemplateReportInstance>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateReportRetrieve.');
        }

        if (requestParameters.templateReportId === null || requestParameters.templateReportId === undefined) {
            throw new runtime.RequiredError('templateReportId','Required parameter requestParameters.templateReportId was null or undefined when calling apiGrexsuperadminClientTemplateReportRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-report/{template_report_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_report_id"}}`, encodeURIComponent(String(requestParameters.templateReportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTemplateReportInstanceFromJSON(jsonValue));
    }

    /**
     * get TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportRetrieve(requestParameters: ApiGrexsuperadminClientTemplateReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTemplateReportInstance> {
        const response = await this.apiGrexsuperadminClientTemplateReportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * put TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportUpdateRaw(requestParameters: ApiGrexsuperadminClientTemplateReportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateReportUpdate.');
        }

        if (requestParameters.templateReportId === null || requestParameters.templateReportId === undefined) {
            throw new runtime.RequiredError('templateReportId','Required parameter requestParameters.templateReportId was null or undefined when calling apiGrexsuperadminClientTemplateReportUpdate.');
        }

        if (requestParameters.clientUpdateTemplateReportRequest === null || requestParameters.clientUpdateTemplateReportRequest === undefined) {
            throw new runtime.RequiredError('clientUpdateTemplateReportRequest','Required parameter requestParameters.clientUpdateTemplateReportRequest was null or undefined when calling apiGrexsuperadminClientTemplateReportUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-report/{template_report_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_report_id"}}`, encodeURIComponent(String(requestParameters.templateReportId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientUpdateTemplateReportRequestToJSON(requestParameters.clientUpdateTemplateReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * put TemplateReport
     */
    async apiGrexsuperadminClientTemplateReportUpdate(requestParameters: ApiGrexsuperadminClientTemplateReportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexsuperadminClientTemplateReportUpdateRaw(requestParameters, initOverrides);
    }

}
