import { RoleEnum, TypeEnum } from "@shared/client/lib";
import {
  AppStateType,
  LoginState,
  MoveOverviewState,
  PopUpState,
  ProjectSortOption,
  ScreenState,
} from "./Types";

export const initialAppState: AppStateType = {
  loggedIn: false,
  currentUser: {
    fullName: "",
    userRole: RoleEnum.Non,
    accessToken: null,
    userEmail: "",
    mfaVerified: false,
    mfaForced: false,
    isSuperAdmin: false,
    mfaInput: "",
    mfaInputTwo: "",
    passwordResetRequired: false,
    termsConditionsAccepted: true,
  },

  popUpState: PopUpState.Hidden,
  screenState: ScreenState.Projects,
  loginState: LoginState.Loading,

  LoginScreen: {
    login_error: "",
  },

  loading: false,
  // login 2fa
  mfaRequired: false,

  login2fa: {
    useremail: "",
    infoMessage: "",
    errorMessage: "",
  },

  // Setup Mfa
  setupMfa: {
    setup_key: "",
    mfaCode: "",
    otpauth_url: "",
    errorMessage: "",
  },

  // reset password
  ResetPassword: {
    email: "",
    resetPasswordCode: "",
    mfaenabled: false,
    mfatoken: "",
    resetpasswordMessage: "",
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    newPasswordError: "",
    newPasswordinfo: "",
    mfaInput: "",
  },

  projectScreen: {
    loadingProjects: false,
    projectsList: [],
    selectedProject: null,
    loadingVersionModels: false,
    versionsList: [],
    selectedVersionModel: null,
    loadingModels: false,
    modelsList: [],
    popUpPosition: {
      x: 0,
      y: 0,
    },
    projectPopUpProjectId: null,
    projectPopUpVersionModelId: null,
    projectPopUpModelId: null,
    typeProjectList: [],
    filterTypeProject: 0,
    subregions: [],
    filterSubregion: 0,
    projectSorting: ProjectSortOption.LastEdited,
    moveOverviewState: MoveOverviewState.Hidden,
    versionModelToMove: null,
    modelToMove: null,
    modelToOpen: null,
    selectedMoveTargetProject: null,
    selectedMoveTargetVersionModel: null,
    moveVersionModelList: [],
    projectToDelete: null,
    versionModelToDelete: null,
    modelToDelete: null,
    modelToCancelSessionEdit: null,
    userEmailModelToCancelEditSession: null,
    errorMessage: "",
  },
  OpenModel: {
    openModelTaskId: 0,
    progress: "",
    error: false,
  },
  newProjectScreen: {
    loadingForm: false,
    loadingRoles: false,
    formError: "",
    projectName: "",
    description: "",
    typeProject: 0,
    projectNumber: "",
    subregion: 0,
    organisation: "",
    projectManager: "",
    userRoles: [],
    typeProjectList: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
    subregions: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
  },

  projectInfoScreen: {
    loadingForm: false,
    loadingRoles: false,
    formError: "",
    projectId: null,
    projectName: "",
    description: "",
    typeProject: 0,
    projectNumber: "",
    subregion: 0,
    organisation: "",
    projectManager: "",
    author: "",
    lastEdited: null,
    lastModifiedByNameFull: "",
    userRoles: [],
    typeProjectList: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
    subregions: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
    projectRole: null,
  },

  newVersionModelScreen: {
    loadingForm: false,
    formError: "",
    versionName: "",
    description: "",
    phases: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
    phase: 0,
  },

  versionInfoScreen: {
    loadingForm: false,
    formError: "",
    versionId: null,
    versionName: "",
    description: "",
    phases: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
    phase: 0,
    author: "",
    lastEdited: null,
    lastModifiedByNameFull: "",
    attachments: null,
    projectRole: null,
    viewerUri: null,
    attachmentToDelete: null,
  },

  versionInfoNewAttachmentScreen: {
    formError: "",
    loadingForm: true,
    attachmentType: TypeEnum.File,
    categoryAttachments: [],
    categoryAttachment: 0,
    linkUrl: null,
    displayName: null,
    file: null,
  },

  newModelScreen: {
    loadingForm: false,
    formError: "",
    modelName: "",
    description: "",
    modelTypes: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
    modelType: 0,
  },

  modelInfoScreen: {
    loadingForm: false,
    formError: "",
    modelId: null,
    modelName: "",
    description: "",
    modelTypes: [
      {
        key: 0,
        name: "Aan het laden...",
        disabled: true,
      },
    ],
    modelType: 0,
    frozen: false,
    author: "",
    lastEdited: null,
    lastModifiedByNameFull: "",
    mutations: null,
    projectRole: null,
    modelHistoryList: [],
    newHistoryFile: null,
    fileUploadSuccessMessage: "",
    fileUploadErrorMessage: "",
  },

  reportScreen: {
    loadingSpinner: false,
    loadingProjects: false,
    projectsList: [],
    projectsHaveModelsList: [],
    selectedProject: null,
    loadingVersionModels: false,
    versionsList: [],
    selectedVersionModel: null,
    loadingModels: false,
    modelsList: [],
    allProjectsSelected: false,
    selectedModels: [],
    formError: "",
    correctSelectedModelAmount: false,
    typeProject: [],
    filterTypeProject: 0,
    subregions: [],
    filterSubregion: 0,
    projectSorting: ProjectSortOption.LastEdited,
    templateReports: [],
    selectedTemplateReport: 0,
    loadAndSelectModel: false,
    errorMessage: "",
    progressPercentage: 0,
  },

  phaseScreen: {
    formError: "",
    phasesList: [],
    newPhase: "",
  },

  subregionScreen: {
    formError: "",
    subregionsList: [],
    newSubregion: "",
    subregionToDelete: null,
  },

  typeProjectScreen: {
    formError: "",
    typeProjectList: [],
    newTypeProject: "",
    typeProjectToDelete: null,
  },

  categoryAttachmentScreen: {
    formError: "",
    categoryAttachmentsList: [],
    newCategory: "",
    categoryAttachmentToDelete: null,
  },

  logScreen: {
    logs: [],
    errorMessage: "",
  },

  userScreen: {
    userId: 0,
    firstName: "",
    lastName: "",
    email: "",
    mfaVerified: false,
    mfaForced: false,
    infoMessage: "",
    errorMessage: "",
  },

  userNewPasswordScreen: {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    newPasswordError: "",
    newPasswordinfo: "",
  },

  UserListScreen: {
    userList: [],
    userlist_message: "",
  },

  NewUser: {
    firstName: "",
    lastName: "",
    email: "",
    role: RoleEnum.Non,
    password: "",
    passwordRepeat: "",
    NewUserError: "",

    subregions: [],
    subregions_message: "",
    templateReports: [],
    templateReports_message: "",
    projectsRoles: [],
    projectsRoles_message: "",
    allProjectRolesDropdown: null,
  },

  EditUser: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    role: RoleEnum.Non,
    password: "",
    passwordRepeat: "",
    mfaVerified: false,
    mfaForced: false,
    blocked: false || undefined,
    lastLogin: null,
    EditUserError: "",

    subregions: [],
    subregions_message: "",
    templateReports: [],
    templateReports_message: "",
    projectsRoles: [],
    projectsRoles_message: "",
    allProjectRolesDropdown: null,

    newPassword: "",
    newPasswordRepeat: "",
    newPasswordError: "",
    newPasswordinfo: "",
  },

  PasswordPolicy: {
    newPasswordRequirements: [
      "Minimaal 12 karakters",
      "Minimaal één hoofdletter",
      "Minimaal één kleine letter",
      "Minimaal één cijfer",
      "Minimaal één speciaal teken",
    ],
    notAppliedRequirements: [],
  },

  // Documentation
  DocumentationScreen: {
    documentationsManual: [],
    documentationsInstructionalVideo: [],
    documentationsReleaseNote: [],
    errorMessage: "",
  },

  SubregionMapScreen: {
    outlineUri: null,
  },

  VersionRegionMapScreen: {
    outlineUri: null,
  },

  FeatureMapScreen: {
    editorUri: null,
  },

  ClientMapScreen: {
    outlineUri: null,
    errorMessage: "",
  },

  OverviewMapScreen: {
    viewerUri: null,
    errorMessage: "",
  },

  NewBiLinkScreen: {
    formError: "",
    description: "",
    selectedModels: [],
  },

  BiLinksScreen: {
    biLinks: [],
    errorMessage: "",
  },
};
export const initialAppStateString: string = JSON.stringify(initialAppState);
