/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiGrexfileserverFileRetrieveRequest {
    filenameOrig: string;
    uuid: string;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Endpoint for downloading a file
     */
    async apiGrexfileserverFileRetrieveRaw(requestParameters: ApiGrexfileserverFileRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.filenameOrig === null || requestParameters.filenameOrig === undefined) {
            throw new runtime.RequiredError('filenameOrig','Required parameter requestParameters.filenameOrig was null or undefined when calling apiGrexfileserverFileRetrieve.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiGrexfileserverFileRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/grexfileserver/file/{uuid}/{filename_orig}`.replace(`{${"filename_orig"}}`, encodeURIComponent(String(requestParameters.filenameOrig))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Endpoint for downloading a file
     */
    async apiGrexfileserverFileRetrieve(requestParameters: ApiGrexfileserverFileRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiGrexfileserverFileRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
