/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelListResponse
 */
export interface ModelListResponse {
    /**
     * 
     * @type {number}
     * @memberof ModelListResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ModelListResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListResponse
     */
    readonly typeModelName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelListResponse
     */
    frozen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelListResponse
     */
    alreadyOpen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelListResponse
     */
    openByCurrentUser: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ModelListResponse
     */
    readonly modelTimestampLastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof ModelListResponse
     */
    versionId: number;
    /**
     * 
     * @type {Date}
     * @memberof ModelListResponse
     */
    readonly versionTimestampLastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof ModelListResponse
     */
    projectId: number;
}

/**
 * Check if a given object implements the ModelListResponse interface.
 */
export function instanceOfModelListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "typeModelName" in value;
    isInstance = isInstance && "frozen" in value;
    isInstance = isInstance && "alreadyOpen" in value;
    isInstance = isInstance && "openByCurrentUser" in value;
    isInstance = isInstance && "modelTimestampLastModified" in value;
    isInstance = isInstance && "versionId" in value;
    isInstance = isInstance && "versionTimestampLastModified" in value;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function ModelListResponseFromJSON(json: any): ModelListResponse {
    return ModelListResponseFromJSONTyped(json, false);
}

export function ModelListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'typeModelName': json['type_model_name'],
        'frozen': json['frozen'],
        'alreadyOpen': json['already_open'],
        'openByCurrentUser': json['open_by_current_user'],
        'modelTimestampLastModified': (new Date(json['model_timestamp_last_modified'])),
        'versionId': json['version_id'],
        'versionTimestampLastModified': (new Date(json['version_timestamp_last_modified'])),
        'projectId': json['project_id'],
    };
}

export function ModelListResponseToJSON(value?: ModelListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'frozen': value.frozen,
        'already_open': value.alreadyOpen,
        'open_by_current_user': value.openByCurrentUser,
        'version_id': value.versionId,
        'project_id': value.projectId,
    };
}

