import { DropDownListItem } from "@shared/components/DropDown";
import { ProjectSortOption } from "../../GrexManagerFrontend/src/Types";
// Functions to convert ProjectSortOption enum to DropDownListItems
export const ProjectSortOptionToDropDown = (): DropDownListItem[] => {
  return (
    Object.keys(ProjectSortOption) as Array<keyof typeof ProjectSortOption>
  ).map((key, index) => {
    let displayName = "???";
    switch (ProjectSortOption[key]) {
      case ProjectSortOption.LastEdited:
        displayName = "Laatst bewerkt";
        break;
      case ProjectSortOption.Alphabetical:
        displayName = "Alfabetisch";
        break;
      case ProjectSortOption.ProjectNumber:
        displayName = "Projectnummer";
        break;
      case ProjectSortOption.LastCreated:
        displayName = "Laatst aangemaakt";
        break;
      default:
        break;
    }
    return {
      key: index,
      name: displayName,
      disabled: false,
    };
  });
};
export const ProjectSortOptionToIndex = (option: ProjectSortOption): number => {
  return Object.values(ProjectSortOption).indexOf(option);
};
export const IndexToProjectSortOption = (
  optionIndex: number
): ProjectSortOption => {
  const key = Object.keys(ProjectSortOption)[
    optionIndex
  ] as keyof typeof ProjectSortOption;
  return ProjectSortOption[key];
};

export const checkPasswordPolicy = (
  requirements: string[],
  password: string,
  notAppliedRequirements: string[]
): void => {
  for (const requirement of requirements) {
    if (!doesPasswordMeetRequirement(requirement, password)) {
      if (!notAppliedRequirements.includes(requirement)) {
        notAppliedRequirements.push(requirement);
      }
    } else {
      if (notAppliedRequirements.includes(requirement)) {
        notAppliedRequirements.splice(
          notAppliedRequirements.indexOf(requirement),
          1
        );
      }
    }
  }
};

export const doesPasswordMeetRequirement = (
  requirement: string,
  password: string
): boolean => {
  if (requirement === "Minimaal 12 karakters") {
    if (password.length < 12) {
      return false;
    }
  }
  if (requirement === "Minimaal één hoofdletter") {
    if (password.toLowerCase() === password) {
      return false;
    }
  }
  if (requirement === "Minimaal één kleine letter") {
    if (password.toUpperCase() === password) {
      return false;
    }
  }
  if (requirement === "Minimaal één cijfer") {
    if (!/\d/.test(password)) {
      return false;
    }
  }
  if (requirement === "Minimaal één speciaal teken") {
    if (!/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password)) {
      return false;
    }
  }
  return true;
};

export const TermsOfUse = async (): Promise<string> => {
  // const html = fs.readFileSync(, "utf-8");
  const response = await fetch("../public/TermsOfUse.html");
  const html = await response.text();

  // Parse the HTML string into a DOM structure
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  function extractText(element: HTMLElement | null): string {
    if (!element) return "";

    let text = "";

    // If the element is a heading or paragraph, add extra line breaks for formatting
    if (["H1", "H2", "H3", "H4", "H5", "H6", "P"].includes(element.tagName)) {
      text += "\n";
    }

    // Iterate over child nodes
    element.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        // Append the text content of text nodes
        text += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // Recursively extract text from element nodes
        text += extractText(node as HTMLElement);
      }
    });

    return text;
  }
  console.log(doc.body);
  // Extract text from the body of the parsed HTML
  return extractText(doc.body).trim();
};
