/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface GrexUserMe
 */
export interface GrexUserMe {
    /**
     * 
     * @type {number}
     * @memberof GrexUserMe
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof GrexUserMe
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GrexUserMe
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GrexUserMe
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserMe
     */
    mfaForced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserMe
     */
    mfaVerified?: boolean;
    /**
     * 
     * @type {RoleEnum}
     * @memberof GrexUserMe
     */
    role?: RoleEnum;
    /**
     * Designates whether the user can log into this admin site.
     * @type {boolean}
     * @memberof GrexUserMe
     */
    isStaff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserMe
     */
    passwordResetRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserMe
     */
    termsConditionsAccepted?: boolean;
}

/**
 * Check if a given object implements the GrexUserMe interface.
 */
export function instanceOfGrexUserMe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function GrexUserMeFromJSON(json: any): GrexUserMe {
    return GrexUserMeFromJSONTyped(json, false);
}

export function GrexUserMeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserMe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mfaForced': !exists(json, 'mfa_forced') ? undefined : json['mfa_forced'],
        'mfaVerified': !exists(json, 'mfa_verified') ? undefined : json['mfa_verified'],
        'role': !exists(json, 'role') ? undefined : RoleEnumFromJSON(json['role']),
        'isStaff': !exists(json, 'is_staff') ? undefined : json['is_staff'],
        'passwordResetRequired': !exists(json, 'password_reset_required') ? undefined : json['password_reset_required'],
        'termsConditionsAccepted': !exists(json, 'terms_conditions_accepted') ? undefined : json['terms_conditions_accepted'],
    };
}

export function GrexUserMeToJSON(value?: GrexUserMe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'mfa_forced': value.mfaForced,
        'mfa_verified': value.mfaVerified,
        'role': RoleEnumToJSON(value.role),
        'is_staff': value.isStaff,
        'password_reset_required': value.passwordResetRequired,
        'terms_conditions_accepted': value.termsConditionsAccepted,
    };
}

