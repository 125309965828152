/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ADM` - ADM
 * * `NON` - NON
 * * `USR` - USR
 * * `VWR` - VWR
 * @export
 */
export const ProjectRoleEnum = {
    Adm: 'ADM',
    Non: 'NON',
    Usr: 'USR',
    Vwr: 'VWR'
} as const;
export type ProjectRoleEnum = typeof ProjectRoleEnum[keyof typeof ProjectRoleEnum];


export function ProjectRoleEnumFromJSON(json: any): ProjectRoleEnum {
    return ProjectRoleEnumFromJSONTyped(json, false);
}

export function ProjectRoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleEnum {
    return json as ProjectRoleEnum;
}

export function ProjectRoleEnumToJSON(value?: ProjectRoleEnum | null): any {
    return value as any;
}

