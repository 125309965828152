/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';

/**
 * 
 * @export
 * @interface AttachmentVersionModelCreateRequest
 */
export interface AttachmentVersionModelCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof AttachmentVersionModelCreateRequest
     */
    version: number;
    /**
     * 
     * @type {TypeEnum}
     * @memberof AttachmentVersionModelCreateRequest
     */
    type: TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AttachmentVersionModelCreateRequest
     */
    categoryAttachment: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentVersionModelCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentVersionModelCreateRequest
     */
    urlLink?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentVersionModelCreateRequest
     */
    file?: string;
}

/**
 * Check if a given object implements the AttachmentVersionModelCreateRequest interface.
 */
export function instanceOfAttachmentVersionModelCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "categoryAttachment" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function AttachmentVersionModelCreateRequestFromJSON(json: any): AttachmentVersionModelCreateRequest {
    return AttachmentVersionModelCreateRequestFromJSONTyped(json, false);
}

export function AttachmentVersionModelCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentVersionModelCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
        'type': TypeEnumFromJSON(json['type']),
        'categoryAttachment': json['category_attachment'],
        'name': json['name'],
        'urlLink': !exists(json, 'url_link') ? undefined : json['url_link'],
        'file': !exists(json, 'file') ? undefined : json['file'],
    };
}

export function AttachmentVersionModelCreateRequestToJSON(value?: AttachmentVersionModelCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'type': TypeEnumToJSON(value.type),
        'category_attachment': value.categoryAttachment,
        'name': value.name,
        'url_link': value.urlLink,
        'file': value.file,
    };
}

