/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SAMLServiceResponse
 */
export interface SAMLServiceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SAMLServiceResponse
     */
    samlActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceResponse
     */
    idpEntityId: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceResponse
     */
    idpSsoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceResponse
     */
    idpSloUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceResponse
     */
    idpX509cert: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceResponse
     */
    spEntityId: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceResponse
     */
    spAcsUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceResponse
     */
    spSlsUrl: string;
}

/**
 * Check if a given object implements the SAMLServiceResponse interface.
 */
export function instanceOfSAMLServiceResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "idpEntityId" in value;
    isInstance = isInstance && "idpSsoUrl" in value;
    isInstance = isInstance && "idpSloUrl" in value;
    isInstance = isInstance && "idpX509cert" in value;
    isInstance = isInstance && "spEntityId" in value;
    isInstance = isInstance && "spAcsUrl" in value;
    isInstance = isInstance && "spSlsUrl" in value;

    return isInstance;
}

export function SAMLServiceResponseFromJSON(json: any): SAMLServiceResponse {
    return SAMLServiceResponseFromJSONTyped(json, false);
}

export function SAMLServiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLServiceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'samlActive': !exists(json, 'saml_active') ? undefined : json['saml_active'],
        'idpEntityId': json['idp_entity_id'],
        'idpSsoUrl': json['idp_sso_url'],
        'idpSloUrl': json['idp_slo_url'],
        'idpX509cert': json['idp_x509cert'],
        'spEntityId': json['sp_entity_id'],
        'spAcsUrl': json['sp_acs_url'],
        'spSlsUrl': json['sp_sls_url'],
    };
}

export function SAMLServiceResponseToJSON(value?: SAMLServiceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saml_active': value.samlActive,
        'idp_entity_id': value.idpEntityId,
        'idp_sso_url': value.idpSsoUrl,
        'idp_slo_url': value.idpSloUrl,
        'idp_x509cert': value.idpX509cert,
        'sp_entity_id': value.spEntityId,
        'sp_acs_url': value.spAcsUrl,
        'sp_sls_url': value.spSlsUrl,
    };
}

