import { ReportGet } from "@shared/client/lib";
import { ModelListResponse } from "@shared/client/lib/models/ModelListResponse";
import { DropDownListItem } from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import {
  AppStateType,
  PopUpState,
  ProjectSortOption,
  ScreenState,
} from "../../Types";
export class ReportStateHandler {
  reportScreenSortProjectList(this: AppStateHandler) {
    // Alphabetical
    if (
      this.state.reportScreen.projectSorting === ProjectSortOption.Alphabetical
    ) {
      this.state.reportScreen.projectsList.sort((a, b) => {
        const nameA = a.name.toLowerCase(); // ignore upper and lowercase
        const nameB = b.name.toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    // ProjectNumber
    else if (
      this.state.reportScreen.projectSorting === ProjectSortOption.ProjectNumber
    ) {
      this.state.reportScreen.projectsList.sort((a, b) => {
        if (a.number == null && b.number != null) {
          return 1;
        }
        if (a.number != null && b.number == null) {
          return -1;
        }
        if (a.number != null && b.number != null) {
          const numberA = a.number.toLowerCase(); // ignore upper and lowercase
          const numberB = b.number.toLowerCase(); // ignore upper and lowercase
          if (numberA < numberB) {
            return -1;
          }
          if (numberA > numberB) {
            return 1;
          }
        }
        return 0;
      });
    }
    // Last edited
    else if (
      this.state.reportScreen.projectSorting === ProjectSortOption.LastEdited
    ) {
      this.state.reportScreen.projectsList.sort((a, b) => {
        const timeA = a.timestampLastModified; // ignore upper and lowercase
        const timeB = b.timestampLastModified; // ignore upper and lowercase
        if (timeA < timeB) {
          return 1;
        }
        if (timeA > timeB) {
          return -1;
        }
        return 0;
      });
    }
    // Last created
    else if (
      this.state.reportScreen.projectSorting === ProjectSortOption.LastCreated
    ) {
      this.state.reportScreen.projectsList.sort((a, b) => {
        const timeA = a.createdAt; // ignore upper and lowercase
        const timeB = b.createdAt; // ignore upper and lowercase
        if (timeA < timeB) {
          return 1;
        }
        if (timeA > timeB) {
          return -1;
        }
        return 0;
      });
    }
  }

  initReportScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Reports;
    this.state.reportScreen.selectedProject = null;
    this.state.reportScreen.selectedVersionModel = null;
    this.state.reportScreen.projectsList = [];
    this.state.reportScreen.versionsList = [];
    this.state.reportScreen.modelsList = [];
    this.state.reportScreen.loadingProjects = true;
    this.state.reportScreen.loadingVersionModels = true;
    this.state.reportScreen.loadingModels = true;
    this.state.reportScreen.typeProject = [];
    this.state.reportScreen.filterTypeProject = 0;
    this.state.reportScreen.subregions = [];
    this.state.reportScreen.filterSubregion = 0;
    this.state.reportScreen.selectedModels = [];
    this.state.reportScreen.allProjectsSelected = false;
    this.state.reportScreen.progressPercentage = 0;
    callback(this.state);

    // Get typeProject
    this.typeProjectApi
      .apiGrexmanagerTypeProjectList()
      .then((typeProject) => {
        this.state.reportScreen.typeProject = [
          {
            key: 0,
            name: "-- alle projecttypen --",
            disabled: false,
          },
        ].concat(
          typeProject.map((x) => {
            const item: DropDownListItem = {
              key: x.id,
              name: x.description,
              disabled: false,
            };
            return item;
          })
        );
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);

        this.state.reportScreen.errorMessage =
          "Fout bij het laden van projecttypen.";
        callback(this.state);
        setTimeout(() => {
          this.state.reportScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    // Get subregions
    this.subregionApi
      .apiGrexmanagerSubregionList()
      .then((subregions) => {
        this.state.reportScreen.subregions = [
          {
            key: 0,
            name: "-- alle deelgebieden --",
            disabled: false,
          },
        ].concat(
          subregions.map((x) => {
            const item: DropDownListItem = {
              key: x.id,
              name: x.description,
              disabled: false,
            };
            return item;
          })
        );
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.reportScreen.errorMessage =
          "Fout bij het laden van deelgebieden.";
        callback(this.state);
        setTimeout(() => {
          this.state.reportScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    // Get report templates
    this.templateReportApi
      .apiGrexmanagerTemplateReportList()
      .then((templateReports) => {
        this.state.reportScreen.templateReports = templateReports;
        // select first one
        if (this.state.reportScreen.templateReports.length >= 1) {
          this.state.reportScreen.selectedTemplateReport =
            this.state.reportScreen.templateReports[0].id;
        }
        callback(this.state);
        this.reportScreenCheckTemplate(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.reportScreen.errorMessage =
          "Fout bij het laden van rapportage sjablonen.";
        callback(this.state);
        setTimeout(() => {
          this.state.reportScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    this.reportScreenReloadProjects(callback);
  }

  reportScreenReloadProjects(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.reportScreen.projectsList = [];
    this.state.reportScreen.versionsList = [];
    this.state.reportScreen.modelsList = [];
    this.state.reportScreen.loadingProjects = true;
    this.state.reportScreen.loadingVersionModels = true;
    this.state.reportScreen.loadingModels = true;
    callback(this.state);

    // Get projects
    this.projectApi
      .apiGrexmanagerProjectList()
      .then((projectsList) => {
        this.state.reportScreen.projectsList = projectsList.map((item) => {
          return {
            id: item.id,
            name: item.name,
            number: item.number,
            subregion: item.subregion,
            typeProject: item.typeProject,
            timestampLastModified: item.timestampLastModified,
            visible: true,
            role: item.role,
            createdAt: item.createdAt || new Date(),
          };
        });
        this.state.reportScreen.loadingProjects = false;
        if (this.state.reportScreen.projectsList.length >= 1) {
          this.reportScreenSortProjectList();
          this.reportScreenFilterProjectListAndSelectFirst();
        }
        callback(this.state);

        this.reportScreenReloadVersionModels(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.reportScreen.errorMessage =
          "Fout bij het laden van projecten.";
        callback(this.state);
        setTimeout(() => {
          this.state.reportScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  reportScreenReloadVersionModels(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.reportScreen.versionsList = [];
    this.state.reportScreen.modelsList = [];
    this.state.reportScreen.loadingVersionModels = true;
    this.state.reportScreen.loadingModels = true;
    callback(this.state);

    // Get versions
    if (this.state.reportScreen.selectedProject !== null) {
      this.versionModelApi
        .apiGrexmanagerVersionModelList({
          projectId: this.state.reportScreen.selectedProject,
        })
        .then((versionModelList) => {
          this.state.reportScreen.versionsList = versionModelList;
          this.state.reportScreen.loadingVersionModels = false;

          if (this.state.reportScreen.versionsList.length >= 1) {
            // select first one
            // TODO:
            if (this.state.reportScreen.selectedVersionModel == null) {
              this.state.reportScreen.selectedVersionModel =
                this.state.reportScreen.versionsList[0].id;
            }
          }
          callback(this.state);

          this.reportScreenReloadModels(callback);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.reportScreen.errorMessage =
            "Fout bij het laden van versies.";
          callback(this.state);
          setTimeout(() => {
            this.state.reportScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  reportScreenReloadModels(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.reportScreen.modelsList = [];
    this.state.reportScreen.loadingModels = true;
    callback(this.state);

    // Get Models
    if (this.state.reportScreen.selectedVersionModel !== null) {
      this.modelApi
        .apiGrexmanagerModelList({
          versionModelId: this.state.reportScreen.selectedVersionModel,
        })
        .then((modelsList) => {
          this.state.reportScreen.modelsList = modelsList;
          this.state.reportScreen.loadingModels = false;
          callback(this.state);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.reportScreen.errorMessage =
            "Fout bij het laden van rekenmodellen.";
          callback(this.state);
          setTimeout(() => {
            this.state.reportScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  reportScreenOnProjectFilterChange(
    this: AppStateHandler,
    filter: {
      typeProject?: number;
      subregion?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (filter.typeProject !== undefined) {
      this.state.reportScreen.filterTypeProject = filter.typeProject;
    }
    if (filter.subregion !== undefined) {
      this.state.reportScreen.filterSubregion = filter.subregion;
    }
    this.reportScreenFilterProjectListAndSelectFirst();
    // Deselect all
    this.state.reportScreen.selectedModels = [];
    this.state.reportScreen.allProjectsSelected = false;
    callback(this.state);
    this.reportScreenReloadVersionModels(callback);
  }

  reportScreenFilterProjectListAndSelectFirst(this: AppStateHandler) {
    this.state.reportScreen.projectsList.forEach((project) => {
      if (
        this.state.reportScreen.filterTypeProject === 0 &&
        this.state.reportScreen.filterSubregion === 0
      ) {
        project.visible = true;
      } else if (
        this.state.reportScreen.filterTypeProject === project.typeProject &&
        this.state.reportScreen.filterSubregion === 0
      ) {
        project.visible = true;
      } else if (
        this.state.reportScreen.filterTypeProject === 0 &&
        this.state.reportScreen.filterSubregion === project.subregion
      ) {
        project.visible = true;
      } else if (
        this.state.reportScreen.filterTypeProject === project.typeProject &&
        this.state.reportScreen.filterSubregion === project.subregion
      ) {
        project.visible = true;
      } else {
        project.visible = false;
      }
    });

    // If the selected project became invisible then select the first visible project and reload versions and models
    if (this.state.reportScreen.selectedProject) {
      const projectIndex = this.state.reportScreen.projectsList.findIndex(
        (object) => {
          return object.id === this.state.reportScreen.selectedProject;
        }
      );
      if (projectIndex > -1) {
        if (!this.state.reportScreen.projectsList[projectIndex].visible) {
          const visibleProjects = this.state.reportScreen.projectsList.filter(
            (object) => {
              return object.visible;
            }
          );
          if (visibleProjects.length >= 1) {
            this.state.reportScreen.selectedProject = visibleProjects[0].id;
            this.state.reportScreen.selectedVersionModel = null;
          } else {
            this.state.reportScreen.selectedProject = null;
            this.state.reportScreen.selectedVersionModel = null;
          }
        }
      }
    } else {
      const visibleProjects = this.state.reportScreen.projectsList.filter(
        (object) => {
          return object.visible;
        }
      );
      if (visibleProjects.length >= 1) {
        this.state.reportScreen.selectedProject = visibleProjects[0].id;
        this.state.reportScreen.selectedVersionModel = null;
      }
    }
  }

  reportScreenUpdateProjectSorting(
    this: AppStateHandler,
    projectSorting: ProjectSortOption,
    callback: (newState: AppStateType) => void
  ) {
    this.state.reportScreen.projectSorting = projectSorting;
    this.reportScreenSortProjectList();
    callback(this.state);
  }

  reportScreenSelectAfterLoad(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // TODO: This might not be the most eligant solution
    if (this.state.reportScreen.loadAndSelectModel) {
      if (this.state.reportScreen.modelsList.length >= 1) {
        if (
          this.state.reportScreen.selectedProject &&
          this.state.reportScreen.selectedVersionModel
        ) {
          this.state.reportScreen.selectedModels.push({
            project: this.state.reportScreen.selectedProject,
            versionModel: this.state.reportScreen.selectedVersionModel,
            model: this.state.reportScreen.modelsList[0].id,
          });
          this.reportScreenCheckTemplate(callback);
          this.checkIfAllProjectsAreSelected(callback);
        }
      }
      this.state.reportScreen.loadAndSelectModel = false;
    }
    callback(this.state);
  }

  checkIfAllProjectsAreSelected(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.reportScreen.projectsHaveModelsList.length < 1) {
      this.state.reportScreen.allProjectsSelected = false;
      callback(this.state);
      return;
    }
    // Check if all projects from the projectsList are in this selectedModels list
    let allProjectsSelected = true;
    for (const project of this.state.reportScreen.projectsHaveModelsList) {
      if (
        !this.state.reportScreen.selectedModels
          .map((x) => {
            return x.project;
          })
          .includes(project)
      ) {
        allProjectsSelected = false;
        break;
      }
    }

    this.state.reportScreen.allProjectsSelected = allProjectsSelected;
    callback(this.state);
  }

  reportScreenOnProjectClick(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.reportScreen.selectedProject !== projectId) {
      // Check if project exists
      const projectIndex = this.state.reportScreen.projectsList.findIndex(
        (object) => {
          return object.id === projectId;
        }
      );
      if (projectIndex > -1) {
        this.state.reportScreen.selectedProject = projectId;
        this.state.reportScreen.versionsList = [];
        this.state.reportScreen.selectedVersionModel = null;
        this.state.reportScreen.modelsList = [];
        this.state.reportScreen.loadingVersionModels = true;
        this.state.reportScreen.loadingModels = true;
        callback(this.state);

        // Get versions
        this.versionModelApi
          .apiGrexmanagerVersionModelList({
            projectId: this.state.reportScreen.selectedProject,
          })
          .then((versionModelList) => {
            this.state.reportScreen.versionsList = versionModelList;
            if (this.state.reportScreen.versionsList.length >= 1) {
              // check if selected version is in new version list => else select first
              const versionIndex =
                this.state.reportScreen.versionsList.findIndex((object) => {
                  return (
                    object.id === this.state.reportScreen.selectedVersionModel
                  );
                });
              if (
                versionIndex === -1 ||
                this.state.reportScreen.selectedVersionModel == null
              ) {
                this.state.reportScreen.selectedVersionModel =
                  this.state.reportScreen.versionsList[0].id;
              }
            }
            this.state.reportScreen.loadingVersionModels = false;
            callback(this.state);

            // Get Models
            if (this.state.reportScreen.selectedVersionModel !== null) {
              this.modelApi
                .apiGrexmanagerModelList({
                  versionModelId: this.state.reportScreen.selectedVersionModel,
                })
                .then((modelsList) => {
                  this.state.reportScreen.modelsList = modelsList;
                  this.state.reportScreen.loadingModels = false;
                  callback(this.state);

                  this.reportScreenSelectAfterLoad(callback);
                })
                .catch((error) => {
                  // TODO: Handle error
                  console.log(error);

                  this.state.reportScreen.errorMessage =
                    "Fout bij het laden van rekenmodellen.";
                  callback(this.state);
                  setTimeout(() => {
                    this.state.reportScreen.errorMessage = "";
                    callback(this.state);
                  }, 5000);
                });
            }
          })
          .catch((error) => {
            // TODO: Handle error
            console.log(error);

            this.state.reportScreen.errorMessage =
              "Fout bij het laden van versies.";
            callback(this.state);
            setTimeout(() => {
              this.state.reportScreen.errorMessage = "";
              callback(this.state);
            }, 5000);
          });
      }
    } else {
      this.reportScreenSelectAfterLoad(callback);
    }
  }

  reportScreenOnVersionModelClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.reportScreen.selectedVersionModel !== versionId) {
      // Check if version exists
      const versionIndex = this.state.reportScreen.versionsList.findIndex(
        (object) => {
          return object.id === versionId;
        }
      );
      if (versionIndex > -1) {
        this.state.reportScreen.selectedVersionModel = versionId;
        // this.state.modelsList = [];
        this.state.reportScreen.loadingModels = true;
        callback(this.state);

        // Get Models
        if (this.state.reportScreen.selectedVersionModel !== null) {
          this.modelApi
            .apiGrexmanagerModelList({
              versionModelId: this.state.reportScreen.selectedVersionModel,
            })
            .then((modelsList) => {
              this.state.reportScreen.modelsList = modelsList;
              this.state.reportScreen.loadingModels = false;

              this.reportScreenSelectAfterLoad(callback);

              callback(this.state);
            })
            .catch((error) => {
              // TODO: Handle error
              console.log(error);

              this.state.reportScreen.errorMessage =
                "Fout bij het laden van rekenmodellen";
              callback(this.state);
              setTimeout(() => {
                this.state.reportScreen.errorMessage = "";
                callback(this.state);
              }, 5000);
            });
        }
      }
    } else {
      this.reportScreenSelectAfterLoad(callback);
    }
  }

  toggleSelectAllProjectsButton(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.checkIfAllProjectsAreSelected(callback);
    if (this.state.reportScreen.allProjectsSelected) {
      // Deselect all
      this.state.reportScreen.selectedModels = [];
      this.state.reportScreen.allProjectsSelected = false;
      callback(this.state);
    } else {
      this.state.reportScreen.loadingSpinner = true;
      callback(this.state);
      this.modelApi.apiGrexmanagerModelList({}).then((modelsList) => {
        this.state.reportScreen.projectsHaveModelsList = modelsList.map(
          (model) => model.projectId
        );
        // Group all the items by projectId
        const groupedByProjectId = modelsList.reduce((acc, model) => {
          if (!acc[model.projectId]) {
            acc[model.projectId] = [];
          }
          acc[model.projectId].push(model);
          return acc;
        }, {} as { [key: number]: ModelListResponse[] });

        // Inside each group, group by the latest versionTimestampLastModified value,
        // and then get the model with the latest modelTimestampLastModified value

        // Select only visible projects
        for (const project of this.state.reportScreen.projectsList) {
          if (project.visible === false && groupedByProjectId[project.id]) {
            delete groupedByProjectId[project.id];
          }
        }
        for (const projectId in groupedByProjectId) {
          const group = groupedByProjectId[projectId];

          // Find the latest versionTimestampLastModified value in the group
          const latestVersionTimestamp = group.reduce((latest, model) => {
            return model.versionTimestampLastModified > latest
              ? model.versionTimestampLastModified
              : latest;
          }, group[0].versionTimestampLastModified);

          // Filter models by the latest versionTimestampLastModified value
          const latestVersionModels = group.filter(
            (model) =>
              model.versionTimestampLastModified === latestVersionTimestamp
          );

          // Find the model with the latest modelTimestampLastModified value in this subset
          const latestModel = latestVersionModels.reduce((latest, model) => {
            return model.modelTimestampLastModified >
              latest.modelTimestampLastModified
              ? model
              : latest;
          });

          // Add this model to the selectedModels
          const selectedModelIds = this.state.reportScreen.selectedModels.map(
            (item) => item.model
          );
          if (!selectedModelIds.includes(latestModel.id)) {
            this.state.reportScreen.selectedModels.push({
              project: latestModel.projectId,
              versionModel: latestModel.versionId,
              model: latestModel.id,
            });
          }
          callback(this.state);
          this.reportScreenCheckTemplate(callback);
        }
        this.state.reportScreen.allProjectsSelected = true;
        this.state.reportScreen.loadingSpinner = false;

        callback(this.state);
        this.reportScreenCheckTemplate(callback);
      });
    }
  }

  reportScreenToggleModel(
    this: AppStateHandler,
    toggle: {
      project?: number;
      versionModel?: number;
      model?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (toggle.model !== undefined) {
      // check if model is already selected
      if (
        this.state.reportScreen.selectedModels.findIndex(
          (object) => object.model === toggle.model
        ) > -1
      ) {
        // deselect all
        let index = this.state.reportScreen.selectedModels.length - 1;
        while (index >= 0) {
          if (
            this.state.reportScreen.selectedModels[index].model === toggle.model
          ) {
            this.state.reportScreen.selectedModels.splice(index, 1);
            this.checkIfAllProjectsAreSelected(callback);
            this.reportScreenCheckTemplate(callback);
          }
          index -= 1;
        }
      } else {
        // Select the model
        if (
          this.state.reportScreen.selectedProject &&
          this.state.reportScreen.selectedVersionModel
        ) {
          this.state.reportScreen.selectedModels.push({
            project: this.state.reportScreen.selectedProject,
            versionModel: this.state.reportScreen.selectedVersionModel,
            model: toggle.model,
          });
          this.checkIfAllProjectsAreSelected(callback);
          this.reportScreenCheckTemplate(callback);
        }
      }
    } else if (toggle.versionModel !== undefined) {
      // check if version is already selected
      if (
        this.state.reportScreen.selectedModels.findIndex(
          (object) => object.versionModel === toggle.versionModel
        ) > -1
      ) {
        // deselect all
        let index = this.state.reportScreen.selectedModels.length - 1;
        while (index >= 0) {
          if (
            this.state.reportScreen.selectedModels[index].versionModel ===
            toggle.versionModel
          ) {
            this.state.reportScreen.selectedModels.splice(index, 1);
            this.checkIfAllProjectsAreSelected(callback);
            this.reportScreenCheckTemplate(callback);
          }
          index -= 1;
        }
      } else {
        console.log("Select versionModel");
        this.state.reportScreen.loadAndSelectModel = true;
      }
    } else if (toggle.project !== undefined) {
      // check if project is already selected
      if (
        this.state.reportScreen.selectedModels.findIndex(
          (object) => object.project === toggle.project
        ) > -1
      ) {
        // deselect all
        let index = this.state.reportScreen.selectedModels.length - 1;
        while (index >= 0) {
          if (
            this.state.reportScreen.selectedModels[index].project ===
            toggle.project
          ) {
            this.state.reportScreen.selectedModels.splice(index, 1);
            this.checkIfAllProjectsAreSelected(callback);
            this.reportScreenCheckTemplate(callback);
          }
          index -= 1;
        }
      } else {
        console.log("Select project");
        this.state.reportScreen.loadAndSelectModel = true;
      }
    }
    callback(this.state);
  }

  reportScreenSelectTemplate(
    this: AppStateHandler,
    selectedTemplateReport: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.reportScreen.selectedTemplateReport = selectedTemplateReport;
    callback(this.state);
    this.reportScreenCheckTemplate(callback);
  }

  reportScreenCheckTemplate(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const templateModelIndex =
      this.state.reportScreen.templateReports.findIndex((object) => {
        return object.id === this.state.reportScreen.selectedTemplateReport;
      });
    if (templateModelIndex > -1) {
      if (
        this.state.reportScreen.selectedModels.length >=
          this.state.reportScreen.templateReports[templateModelIndex]
            .nModelsMin &&
        this.state.reportScreen.selectedModels.length <=
          this.state.reportScreen.templateReports[templateModelIndex].nModelsMax
      ) {
        this.state.reportScreen.correctSelectedModelAmount = true;
        this.state.reportScreen.formError = "";
      } else if (
        this.state.reportScreen.selectedModels.length <
        this.state.reportScreen.templateReports[templateModelIndex].nModelsMin
      ) {
        this.state.reportScreen.correctSelectedModelAmount = false;
        this.state.reportScreen.formError = `Selecteer minimaal ${this.state.reportScreen.templateReports[templateModelIndex].nModelsMin} rekenmodellen.`;
      } else if (
        this.state.reportScreen.selectedModels.length >
        this.state.reportScreen.templateReports[templateModelIndex].nModelsMax
      ) {
        this.state.reportScreen.correctSelectedModelAmount = false;
        this.state.reportScreen.formError = `Selecteer maximaal ${this.state.reportScreen.templateReports[templateModelIndex].nModelsMax} rekenmodellen.`;
      }
    }
    callback(this.state);
  }

  checkIfReportIsReady(
    this: AppStateHandler,
    uuid: string,
    callback: (newState: AppStateType) => void
  ) {
    this.reportApi
      .apiGrexmanagerReportRetrieveRaw({ uuid: uuid })
      .then(async (rawResponse) => {
        const statusCode = rawResponse.raw.status;
        if (statusCode === 200) {
          // Get the response body
          const response: ReportGet = await rawResponse.value();

          // Open the report
          const link = document.createElement("a");
          link.href = `ms-excel:ofv|u|${this.basePath()}/api/grexfileserver/file/${
            response.uuid
          }/${response.filenameOrig}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // close the popup
          this.closeAlert(callback);
        } else if (statusCode === 202) {
          // try again in 5 seconds
          const response: ReportGet = await rawResponse.value();
          this.state.reportScreen.progressPercentage = response.progress || 0;
          callback(this.state);
          setTimeout(() => {
            this.checkIfReportIsReady(uuid, callback);
          }, 1000);
        }
      })
      .catch((error) => {
        // Close the alert
        this.closeAlert(callback);
        console.log(error);

        // Show message to the user
        this.state.reportScreen.errorMessage =
          "Fout bij het maken van rapportage.";
        callback(this.state);
        setTimeout(() => {
          this.state.reportScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  reportScreenMakeReport(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // Set the pop up state to loading
    this.state.reportScreen.progressPercentage = 0;
    callback(this.state);
    this.state.popUpState = PopUpState.OpenReportLoading;
    // Update the state with the new state
    callback(this.state);
    const templateModelIndex =
      this.state.reportScreen.templateReports.findIndex((object) => {
        return object.id === this.state.reportScreen.selectedTemplateReport;
      });
    if (templateModelIndex > -1) {
      this.reportApi
        .apiGrexmanagerReportCreate({
          report: {
            templateReport:
              this.state.reportScreen.templateReports[templateModelIndex].id,
            models: this.state.reportScreen.selectedModels.map((x) => x.model),
          },
        })
        .then((response) => {
          this.checkIfReportIsReady(response.uuid, callback);
        })
        .catch((error) => {
          // TODO: Handle error
          this.closeAlert(callback); // Set the pop up state to hidden
          console.log(error);

          this.state.reportScreen.errorMessage =
            "Fout bij het maken van rapportage.";
          callback(this.state);
          setTimeout(() => {
            this.state.reportScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }
}
