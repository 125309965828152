/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientCreateTemplateModel
 */
export interface ClientCreateTemplateModel {
    /**
     * 
     * @type {string}
     * @memberof ClientCreateTemplateModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateTemplateModel
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateTemplateModel
     */
    datasheetName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateTemplateModel
     */
    file: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateTemplateModel
     */
    active: boolean;
}

/**
 * Check if a given object implements the ClientCreateTemplateModel interface.
 */
export function instanceOfClientCreateTemplateModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "datasheetName" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function ClientCreateTemplateModelFromJSON(json: any): ClientCreateTemplateModel {
    return ClientCreateTemplateModelFromJSONTyped(json, false);
}

export function ClientCreateTemplateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCreateTemplateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'datasheetName': json['datasheet_name'],
        'file': json['file'],
        'active': json['active'],
    };
}

export function ClientCreateTemplateModelToJSON(value?: ClientCreateTemplateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'datasheet_name': value.datasheetName,
        'file': value.file,
        'active': value.active,
    };
}

