import { RoleEnum } from "@shared/client/lib";
import LoadingOverlay from "@shared/components/LoadingOverlay";
import MessagePopup from "@shared/components/MessagePopup";
import React from "react";
import "./App.css";
import { AppStateHandler } from "./AppStateHandler";
import { AppStateType, LoginState, PopUpState, ScreenState } from "./Types";
import InfoMessagePopup from "./components/InfoMessagePopup";
import MenuBar from "./components/MenuBar";
import CreateUserScreen from "./screens/admin/CreateUserScreen";
import EditUserScreen from "./screens/admin/EditUserScreen";
import ResetPasswordUserScreen from "./screens/admin/ResetPasswordUserScreen";
import UserListScreen from "./screens/admin/UserListScreen";
import LoadingScreen from "./screens/auth/LoadingScreen";
import LoginMfaScreen from "./screens/auth/LoginMfaScreen";
import LoginSamlScreen from "./screens/auth/LoginSamlScreen";
import LoginScreen from "./screens/auth/LoginScreen";
import NoneScreen from "./screens/auth/NoneScreen";
import ResetPasswordCodeScreen from "./screens/auth/ResetPasswordCodeScreen";
import ResetPasswordEmailScreen from "./screens/auth/ResetPasswordEmailScreen";
import ResetPasswordMfaScreen from "./screens/auth/ResetPasswordMfaScreen";
import ResetPasswordNewPasswordScreen from "./screens/auth/ResetPasswordNewPasswordScreen";
import CategoriesAttachmentScreen from "./screens/configuration/CategoryAttachmentScreen";
import PhaseScreen from "./screens/configuration/PhaseScreen";
import SubregionsScreen from "./screens/configuration/SubregionScreen";
import Exploitatietypen from "./screens/configuration/TypeProjectScreen";
import DocumentationScreen from "./screens/docs/DocumentationScreen";
import LogScreen from "./screens/log/LogScreen";
import ClientMapScreen from "./screens/mapviewer/ClientMapScreen";
import FeatureMapScreen from "./screens/mapviewer/FeatureMapScreen";
import OverviewMapScreen from "./screens/mapviewer/OverviewMapScreen";
import SubregionMapScreen from "./screens/mapviewer/SubregionMapScreen";
import VersionRegionMapScreen from "./screens/mapviewer/VersionRegionMapScreen";
import ModelInfoScreen from "./screens/project/ModelInfoScreen";
import NewModelScreen from "./screens/project/NewModelScreen";
import NewProjectScreen from "./screens/project/NewProjectScreen";
import NewVersionModelScreen from "./screens/project/NewVersionModelScreen";
import ProjectInfoScreen from "./screens/project/ProjectInfoScreen";
import ProjectScreen from "./screens/project/ProjectScreen";
import VersionModelInfoScreen from "./screens/project/VersionModelInfoScreen";
import VersionModelNewAttachmentScreen from "./screens/project/VersionModelNewAttachmentScreen";
import BiLinksScreen from "./screens/report/BiLinksScreen";
import NewBiLinkScreen from "./screens/report/NewBiLinkScreen";
import ReportScreen from "./screens/report/ReportScreen";
import SetupMfaScreen from "./screens/user/SetupMfaScreen";
import MyAccountScreen from "./screens/user/UserScreen";
export default class App extends React.Component {
  stateHandler = new AppStateHandler();
  state: AppStateType;

  constructor(props: any) {
    super(props);
    this.state = this.stateHandler.getState();
  }

  componentDidMount() {
    this.stateHandler.initApp((newState) => {
      this.setState(newState);
    });
  }

  render() {
    return (
      <>
        {(() => {
          if (!this.state.loggedIn) {
            if (this.state.loginState === LoginState.Loading) {
              return (
                <LoadingScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                />
              );
            } else if (
              this.state.loginState === LoginState.ResetPasswordEmail
            ) {
              return (
                <ResetPasswordEmailScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  loginstate={this.state.loginState}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                />
              );
            } else if (
              this.state.loginState === LoginState.LoginEmailPassword
            ) {
              return (
                <LoginScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  loginstate={this.state.loginState}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                ></LoginScreen>
              );
            } else if (this.state.loginState === LoginState.LoginSaml) {
              return (
                <LoginSamlScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                ></LoginSamlScreen>
              );
            } else if (this.state.loginState === LoginState.ResetPasswordCode) {
              return (
                <ResetPasswordCodeScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  loginstate={this.state.loginState}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                />
              );
            } else if (this.state.loginState === LoginState.ResetPasswordMFA) {
              return (
                <ResetPasswordMfaScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  loginstate={this.state.loginState}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                />
              );
            } else if (
              this.state.loginState === LoginState.ResetPasswordNewPassword
            ) {
              return (
                <ResetPasswordNewPasswordScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  loginstate={this.state.loginState}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                />
              );
            } else if (this.state.mfaRequired) {
              return (
                <LoginMfaScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                ></LoginMfaScreen>
              );
            }
          } else {
            if (this.state.currentUser.userRole === RoleEnum.Non) {
              return (
                <NoneScreen
                  state={this.state}
                  stateHandler={this.stateHandler}
                  loginstate={this.state.loginState}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                />
              );
            } else if (
              this.state.currentUser.userRole === RoleEnum.Adm ||
              this.state.currentUser.userRole === RoleEnum.Usr ||
              this.state.currentUser.userRole === RoleEnum.Vwr
            ) {
              return (
                <div id="App-Container">
                  {(() => {
                    if (this.state.loading) {
                      return <LoadingOverlay />;
                    }
                  })()}

                  {(() => {
                    if (
                      this.state.popUpState ===
                      PopUpState.DeleteTypeProjectAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "Dit projecttype is nog gekoppeld aan één of meerdere projecten. Als u het projecttype verwijdert, worden ook alle gekoppelde projecten verwijderd. Wilt u hiermee doorgaan?"
                          }
                          okayCallback={() => {
                            this.stateHandler.permanentlyDeleteTypeProject(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeDeleteTypeProjectAlert(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.DeleteSubregionAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "Dit deelgebied is nog gekoppeld aan één of meerdere projecten. Als u het deelgebied verwijdert, worden ook alle gekoppelde projecten verwijderd. Wilt u hiermee doorgaan?"
                          }
                          okayCallback={() => {
                            this.stateHandler.permanentlyDeleteSubregion(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.DeleteAttachmentAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "Weet u zeker dat u deze bijlage wilt verwijderen?"
                          }
                          okayCallback={() => {
                            this.stateHandler.permanentlyVersionInfoDeleteAttachment(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState ===
                      PopUpState.DeleteVersionModelAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "Weet u zeker dat u deze versie wilt verwijderen?"
                          }
                          okayCallback={() => {
                            this.stateHandler.permanentlyDeleteVersionModel(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.DeleteProjectAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "Weet u zeker dat u dit project wilt verwijderen?"
                          }
                          okayCallback={() => {
                            this.stateHandler.permanentlyDeleteProject(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState ===
                      PopUpState.CancelModelSessionEditAlert
                    ) {
                      const user_email =
                        this.state.projectScreen
                          .userEmailModelToCancelEditSession;
                      let popupmessage: string;

                      if (user_email === this.state.currentUser.userEmail) {
                        popupmessage =
                          "Weet u zeker dat u uw actieve sessie wilt beëindigen?";
                      } else {
                        popupmessage = `Weet u zeker dat u de sessie van gebruiker ${user_email} wilt beëindigen?`;
                      }
                      console.log(
                        popupmessage,
                        "currentUser_useremail",
                        this.state.currentUser.userEmail,
                        "user_email",
                        user_email
                      );
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={`${popupmessage} Niet-opgeslagen werk gaat hierdoor verloren.`}
                          okayButtonText="Beëindig"
                          okayCallback={() => {
                            this.stateHandler.permanentlyCancelModelSessionEdit(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.DeleteModelAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "Weet u zeker dat u dit rekenmodel wilt verwijderen?"
                          }
                          okayCallback={() => {
                            this.stateHandler.permanentlyDeleteModel(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.OpenModelLoading
                    ) {
                      return (
                        <InfoMessagePopup
                          massageTitle="Rekenmodel aan het openen"
                          message={
                            "Het rekenmodel wordt voorbereid en zal automatisch geopend worden."
                          }
                          progressmessage={this.state.OpenModel.progress}
                          error={this.state.OpenModel.error}
                        ></InfoMessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.ModelAlreadyOpenAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Rekenmodel is al geopend"
                          message={"Dit rekenmodel is al geopend."}
                          okayButtonText="Toch openen"
                          okayCallback={() => {
                            if (this.state.projectScreen.modelToOpen) {
                              this.stateHandler.openModel(
                                this.state.projectScreen.modelToOpen,
                                (newState) => {
                                  this.setState(newState);
                                },
                                true
                              );
                            }
                          }}
                          cancelButtonText="Ga terug"
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState ===
                      PopUpState.DeleteCategoryAttachmentAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "Deze bijlagecategorie is nog gekoppeld aan één of meerdere bijlagen. Als u de bijlagecategorie verwijdert, worden ook alle gekoppelde bijlagen verwijderd. Wilt u hiermee doorgaan?"
                          }
                          okayCallback={() => {
                            this.stateHandler.onCategoryAttachmentDeleteClick(
                              (newState) => {
                                this.setState(newState);
                              },
                              undefined,
                              true
                            );
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                          cancelCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.OpenReportLoading
                    ) {
                      return (
                        <InfoMessagePopup
                          massageTitle="Rapportage aan het openen"
                          message={
                            "De rapportage wordt voorbereid en zal automatisch geopend worden. Als u veel modellen heeft geselecteerd, kan dit enkele minuten duren."
                          }
                          progress={this.state.reportScreen.progressPercentage}
                          closeCallback={() => {
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></InfoMessagePopup>
                      );
                    } else if (
                      this.state.popUpState === PopUpState.TermsConditionsWindow
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="GrexManager Gebruikersvoorwaarden rekenmodellen"
                          message={""}
                          okayButtonText="Accepteren"
                          cancelButtonText="Uitloggen"
                          warning={false}
                          okayCallback={() => {
                            this.stateHandler.TermsConditionAccept(
                              (newState) => {
                                this.setState(newState);
                              }
                            );
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                          cancelCallback={() => {
                            this.stateHandler.logout((newState) => {
                              this.setState(newState);
                            });
                          }}
                          TermOfUse={true}
                        ></MessagePopup>
                      );
                    } else if (
                      this.state.popUpState ===
                      PopUpState.PasswordResetRequiredAlert
                    ) {
                      return (
                        <MessagePopup
                          massageTitle="Waarschuwing!"
                          message={
                            "U bent ingelogd met een tijdelijk wachtwoord. Wijzig dit naar een nieuw wachtwoord om verder te gaan."
                          }
                          okayButtonText="Gebruikersinstellingen"
                          cancelButtonText="Uitloggen"
                          warning={false}
                          okayCallback={() => {
                            this.stateHandler.loadUserinfo((newState) => {
                              this.setState(newState);
                            });
                            this.stateHandler.closeAlert((newState) => {
                              this.setState(newState);
                            });
                          }}
                          cancelCallback={() => {
                            this.stateHandler.logout((newState) => {
                              this.setState(newState);
                            });
                          }}
                        ></MessagePopup>
                      );
                    }
                  })()}

                  <MenuBar
                    state={this.state}
                    stateHandler={this.stateHandler}
                    updateStateCallback={(newState) => {
                      this.setState(newState);
                    }}
                  ></MenuBar>
                  <div className="App-Screen">
                    {(() => {
                      if (this.state.screenState === ScreenState.Projects) {
                        return (
                          <ProjectScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></ProjectScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Projects_NewProject
                      ) {
                        return (
                          <NewProjectScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></NewProjectScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Projects_ProjectInfo
                      ) {
                        return (
                          <ProjectInfoScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></ProjectInfoScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Projects_NewVersionModel
                      ) {
                        return (
                          <NewVersionModelScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></NewVersionModelScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Projects_VersionModelInfo
                      ) {
                        return (
                          <VersionModelInfoScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></VersionModelInfoScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Projects_VersionModelInfo_NewAttachment
                      ) {
                        return (
                          <VersionModelNewAttachmentScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.Projects_NewModel
                      ) {
                        return (
                          <NewModelScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></NewModelScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Projects_ModelInfo
                      ) {
                        return (
                          <ModelInfoScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></ModelInfoScreen>
                        );
                      } else if (
                        this.state.screenState === ScreenState.Reports
                      ) {
                        return (
                          <ReportScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></ReportScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Configuration_PhaseDefinitions
                      ) {
                        return (
                          <PhaseScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          ></PhaseScreen>
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Configuration_TypeProject
                      ) {
                        return (
                          <Exploitatietypen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Configuration_Subregions
                      ) {
                        return (
                          <SubregionsScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.Configuration_FileCategories
                      ) {
                        return (
                          <CategoriesAttachmentScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (this.state.screenState === ScreenState.Logs) {
                        return (
                          <LogScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (this.state.screenState === ScreenState.User) {
                        return (
                          <MyAccountScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.SetupMfa
                      ) {
                        return (
                          <SetupMfaScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.UserList
                      ) {
                        return (
                          <UserListScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.CreateUser
                      ) {
                        return (
                          <CreateUserScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.EditUser
                      ) {
                        return (
                          <EditUserScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.ResetPasswordUser
                      ) {
                        return (
                          <ResetPasswordUserScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.Documentation
                      ) {
                        return (
                          <DocumentationScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.MapViewer_VersionRegion
                      ) {
                        return (
                          <VersionRegionMapScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.MapViewer_Subregion
                      ) {
                        return (
                          <SubregionMapScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.MapViewer_Feature
                      ) {
                        return (
                          <FeatureMapScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState ===
                        ScreenState.MapViewer_Overview
                      ) {
                        return (
                          <OverviewMapScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.MapViewer_Client
                      ) {
                        return (
                          <ClientMapScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.NewBiLink
                      ) {
                        return (
                          <NewBiLinkScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      } else if (
                        this.state.screenState === ScreenState.BiLinks
                      ) {
                        return (
                          <BiLinksScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateStateCallback={(newState) => {
                              this.setState(newState);
                            }}
                          />
                        );
                      }
                    })()}
                  </div>
                </div>
              );
            }
          }
        })()}
      </>
    );
  }
}
