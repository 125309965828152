/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubregionCreateAccessRequest } from './SubregionCreateAccessRequest';
import {
    SubregionCreateAccessRequestFromJSON,
    SubregionCreateAccessRequestFromJSONTyped,
    SubregionCreateAccessRequestToJSON,
} from './SubregionCreateAccessRequest';

/**
 * 
 * @export
 * @interface SubregionCreateRequest
 */
export interface SubregionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SubregionCreateRequest
     */
    description: string;
    /**
     * 
     * @type {Array<SubregionCreateAccessRequest>}
     * @memberof SubregionCreateRequest
     */
    accessList: Array<SubregionCreateAccessRequest>;
}

/**
 * Check if a given object implements the SubregionCreateRequest interface.
 */
export function instanceOfSubregionCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "accessList" in value;

    return isInstance;
}

export function SubregionCreateRequestFromJSON(json: any): SubregionCreateRequest {
    return SubregionCreateRequestFromJSONTyped(json, false);
}

export function SubregionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubregionCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'accessList': ((json['access_list'] as Array<any>).map(SubregionCreateAccessRequestFromJSON)),
    };
}

export function SubregionCreateRequestToJSON(value?: SubregionCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'access_list': ((value.accessList as Array<any>).map(SubregionCreateAccessRequestToJSON)),
    };
}

