/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectRoleRequest } from './ProjectRoleRequest';
import {
    ProjectRoleRequestFromJSON,
    ProjectRoleRequestFromJSONTyped,
    ProjectRoleRequestToJSON,
} from './ProjectRoleRequest';

/**
 * 
 * @export
 * @interface ProjectCreateRequest
 */
export interface ProjectCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    organisation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    number: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    projectmanager: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    subregion: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    typeProject: number;
    /**
     * 
     * @type {Array<ProjectRoleRequest>}
     * @memberof ProjectCreateRequest
     */
    roles: Array<ProjectRoleRequest>;
}

/**
 * Check if a given object implements the ProjectCreateRequest interface.
 */
export function instanceOfProjectCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "organisation" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "projectmanager" in value;
    isInstance = isInstance && "subregion" in value;
    isInstance = isInstance && "typeProject" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function ProjectCreateRequestFromJSON(json: any): ProjectCreateRequest {
    return ProjectCreateRequestFromJSONTyped(json, false);
}

export function ProjectCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'organisation': json['organisation'],
        'name': json['name'],
        'number': json['number'],
        'projectmanager': json['projectmanager'],
        'subregion': json['subregion'],
        'typeProject': json['type_project'],
        'roles': ((json['roles'] as Array<any>).map(ProjectRoleRequestFromJSON)),
    };
}

export function ProjectCreateRequestToJSON(value?: ProjectCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'organisation': value.organisation,
        'name': value.name,
        'number': value.number,
        'projectmanager': value.projectmanager,
        'subregion': value.subregion,
        'type_project': value.typeProject,
        'roles': ((value.roles as Array<any>).map(ProjectRoleRequestToJSON)),
    };
}

