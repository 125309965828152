/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PatchedTypeProject,
  TypeProject,
} from '../models';
import {
    PatchedTypeProjectFromJSON,
    PatchedTypeProjectToJSON,
    TypeProjectFromJSON,
    TypeProjectToJSON,
} from '../models';

export interface ApiGrexmanagerTypeProjectCreateRequest {
    typeProject: TypeProject;
}

export interface ApiGrexmanagerTypeProjectDestroyRequest {
    id: number;
    force?: boolean;
}

export interface ApiGrexmanagerTypeProjectPartialUpdateRequest {
    id: number;
    patchedTypeProject?: PatchedTypeProject;
}

export interface ApiGrexmanagerTypeProjectRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerTypeProjectUpdateRequest {
    id: number;
    typeProject: TypeProject;
}

/**
 * 
 */
export class TypeProjectApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a project type
     */
    async apiGrexmanagerTypeProjectCreateRaw(requestParameters: ApiGrexmanagerTypeProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeProject>> {
        if (requestParameters.typeProject === null || requestParameters.typeProject === undefined) {
            throw new runtime.RequiredError('typeProject','Required parameter requestParameters.typeProject was null or undefined when calling apiGrexmanagerTypeProjectCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/type-project/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeProjectToJSON(requestParameters.typeProject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeProjectFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a project type
     */
    async apiGrexmanagerTypeProjectCreate(requestParameters: ApiGrexmanagerTypeProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeProject> {
        const response = await this.apiGrexmanagerTypeProjectCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a project type
     */
    async apiGrexmanagerTypeProjectDestroyRaw(requestParameters: ApiGrexmanagerTypeProjectDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerTypeProjectDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/type-project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a project type
     */
    async apiGrexmanagerTypeProjectDestroy(requestParameters: ApiGrexmanagerTypeProjectDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerTypeProjectDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of project types
     */
    async apiGrexmanagerTypeProjectListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TypeProject>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/type-project/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TypeProjectFromJSON));
    }

    /**
     * Endpoint for receiving a list of project types
     */
    async apiGrexmanagerTypeProjectList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TypeProject>> {
        const response = await this.apiGrexmanagerTypeProjectListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a project type
     */
    async apiGrexmanagerTypeProjectPartialUpdateRaw(requestParameters: ApiGrexmanagerTypeProjectPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeProject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerTypeProjectPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/type-project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTypeProjectToJSON(requestParameters.patchedTypeProject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeProjectFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a project type
     */
    async apiGrexmanagerTypeProjectPartialUpdate(requestParameters: ApiGrexmanagerTypeProjectPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeProject> {
        const response = await this.apiGrexmanagerTypeProjectPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiveing information about a project type
     */
    async apiGrexmanagerTypeProjectRetrieveRaw(requestParameters: ApiGrexmanagerTypeProjectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeProject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerTypeProjectRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/type-project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeProjectFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiveing information about a project type
     */
    async apiGrexmanagerTypeProjectRetrieve(requestParameters: ApiGrexmanagerTypeProjectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeProject> {
        const response = await this.apiGrexmanagerTypeProjectRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a project type
     */
    async apiGrexmanagerTypeProjectUpdateRaw(requestParameters: ApiGrexmanagerTypeProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeProject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerTypeProjectUpdate.');
        }

        if (requestParameters.typeProject === null || requestParameters.typeProject === undefined) {
            throw new runtime.RequiredError('typeProject','Required parameter requestParameters.typeProject was null or undefined when calling apiGrexmanagerTypeProjectUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/type-project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TypeProjectToJSON(requestParameters.typeProject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeProjectFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a project type
     */
    async apiGrexmanagerTypeProjectUpdate(requestParameters: ApiGrexmanagerTypeProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeProject> {
        const response = await this.apiGrexmanagerTypeProjectUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
