/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LinkBI,
  LinkBICreateRequest,
  LinkBIPassword,
} from '../models';
import {
    LinkBIFromJSON,
    LinkBIToJSON,
    LinkBICreateRequestFromJSON,
    LinkBICreateRequestToJSON,
    LinkBIPasswordFromJSON,
    LinkBIPasswordToJSON,
} from '../models';

export interface ApiGrexbiV1ReportCreateRequest {
    linkBICreateRequest: LinkBICreateRequest;
}

export interface ApiGrexbiV1ReportDestroyRequest {
    uuid: string;
}

export interface ApiGrexbiV1ReportPasswordCreateRequest {
    uuid: string;
}

export interface ApiGrexbiV1ReportRetrieveRequest {
    uuid: string;
}

/**
 * 
 */
export class LinkBIApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a BI link
     */
    async apiGrexbiV1ReportCreateRaw(requestParameters: ApiGrexbiV1ReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkBI>> {
        if (requestParameters.linkBICreateRequest === null || requestParameters.linkBICreateRequest === undefined) {
            throw new runtime.RequiredError('linkBICreateRequest','Required parameter requestParameters.linkBICreateRequest was null or undefined when calling apiGrexbiV1ReportCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexbi/v1/report/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkBICreateRequestToJSON(requestParameters.linkBICreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkBIFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a BI link
     */
    async apiGrexbiV1ReportCreate(requestParameters: ApiGrexbiV1ReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkBI> {
        const response = await this.apiGrexbiV1ReportCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a BI link.
     */
    async apiGrexbiV1ReportDestroyRaw(requestParameters: ApiGrexbiV1ReportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiGrexbiV1ReportDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexbi/v1/report/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a BI link.
     */
    async apiGrexbiV1ReportDestroy(requestParameters: ApiGrexbiV1ReportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexbiV1ReportDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of BI links
     */
    async apiGrexbiV1ReportListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LinkBI>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexbi/v1/report/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LinkBIFromJSON));
    }

    /**
     * Endpoint for receiving a list of BI links
     */
    async apiGrexbiV1ReportList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LinkBI>> {
        const response = await this.apiGrexbiV1ReportListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for regenerating a BI link password.
     */
    async apiGrexbiV1ReportPasswordCreateRaw(requestParameters: ApiGrexbiV1ReportPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkBIPassword>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiGrexbiV1ReportPasswordCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexbi/v1/report/{uuid}/password/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkBIPasswordFromJSON(jsonValue));
    }

    /**
     * Endpoint for regenerating a BI link password.
     */
    async apiGrexbiV1ReportPasswordCreate(requestParameters: ApiGrexbiV1ReportPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkBIPassword> {
        const response = await this.apiGrexbiV1ReportPasswordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for opening a BI link.
     */
    async apiGrexbiV1ReportRetrieveRaw(requestParameters: ApiGrexbiV1ReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiGrexbiV1ReportRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexbi/v1/report/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for opening a BI link.
     */
    async apiGrexbiV1ReportRetrieve(requestParameters: ApiGrexbiV1ReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexbiV1ReportRetrieveRaw(requestParameters, initOverrides);
    }

}
