/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.22.22.42
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    schemaName: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Client
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Client
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    isActive?: boolean;
}

/**
 * Check if a given object implements the Client interface.
 */
export function instanceOfClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "schemaName" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function ClientFromJSON(json: any): Client {
    return ClientFromJSONTyped(json, false);
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'schemaName': json['schema_name'],
        'name': json['name'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
    };
}

export function ClientToJSON(value?: Client | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schema_name': value.schemaName,
        'name': value.name,
        'is_active': value.isActive,
    };
}

