/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubregionListCreateResponse
 */
export interface SubregionListCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof SubregionListCreateResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof SubregionListCreateResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SubregionListCreateResponse
     */
    viewerUri: string;
    /**
     * 
     * @type {string}
     * @memberof SubregionListCreateResponse
     */
    outlineUri: string;
}

/**
 * Check if a given object implements the SubregionListCreateResponse interface.
 */
export function instanceOfSubregionListCreateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "viewerUri" in value;
    isInstance = isInstance && "outlineUri" in value;

    return isInstance;
}

export function SubregionListCreateResponseFromJSON(json: any): SubregionListCreateResponse {
    return SubregionListCreateResponseFromJSONTyped(json, false);
}

export function SubregionListCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubregionListCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'viewerUri': json['viewer_uri'],
        'outlineUri': json['outline_uri'],
    };
}

export function SubregionListCreateResponseToJSON(value?: SubregionListCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'viewer_uri': value.viewerUri,
        'outline_uri': value.outlineUri,
    };
}

