/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.01.24.11.29
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `INACTIVE` - Inactive
 * * `ACT_FREE` - Active Free
 * * `ACT_AUTH` - Active Auth
 * @export
 */
export const StateEnum = {
    Inactive: 'INACTIVE',
    ActFree: 'ACT_FREE',
    ActAuth: 'ACT_AUTH'
} as const;
export type StateEnum = typeof StateEnum[keyof typeof StateEnum];


export function StateEnumFromJSON(json: any): StateEnum {
    return StateEnumFromJSONTyped(json, false);
}

export function StateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateEnum {
    return json as StateEnum;
}

export function StateEnumToJSON(value?: StateEnum | null): any {
    return value as any;
}

